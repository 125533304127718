<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap">
      <h1>로케이션관리 {{ isNew ? '등록' : '수정' }}</h1>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>기본정보</h2>
      </div>
      <ul class="filter_list">
        <li>
          <dd><label>회사명<span class="forg"> *</span></label>
            <ul>
              <li v-if="isNew">
                <common-multi-drop-down
                    ref="cmpyCdRef"
                    :params="{ searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY] }"
                    v-model="locaInfo.cmpyCd"
                    :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
                    :data-value-field="'cmpyCd'"
                    :data-text-field="'cmpyNm'"
                    @change="initAllData"
                >
                </common-multi-drop-down>
              </li>
              <li v-else>{{ locaInfo.cmpyNm}}</li>
            </ul>
          </dd>
          <dd v-if="!isNew"><label>회사코드</label>
            <ul>
              <li>{{ locaInfo.cmpyCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>로케이션명<span class="forg"> *</span></label>
            <ul>
              <li>
                <input
                    ref="locaNmRef"
                    class="k-textbox w75"
                    v-model.trim="locaInfo.locaNm"
                    @change="()=>{
                      this.isCheckLocaNm = false
                      this.isPassLocaNm = false
                    }"
                />
                <button
                    class="mid_btn orange ml5"
                    :disabled="isDisabledCheck || isPassLocaNm"
                    @click="onClickCheckLocaNm">중복체크
                </button>
              </li>
            </ul>
          </dd>
          <dd v-if="!isNew"><label>로케이션코드</label>
            <ul>
              <li>{{ locaInfo.locaCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
          <label>주소<span class="forg"> *</span></label>
            <ul class="w80">
              <li>
                <input
                    ref="addrRef"
                    class="k-textbox w75"
                    placeholder="주소"
                    :readonly="true"
                    v-model.trim="locaInfo.locaAddr"
                    @click="$refs.postcodeWindowRef.$refs.daumPostCodeRef.kendoWidget().center().open()"
                />
                <button
                    class="mid_btn orange ml5"
                    @click="$refs.postcodeWindowRef.$refs.daumPostCodeRef.kendoWidget().center().open()">주소검색
                </button>
              </li>
            </ul>
          </dd>
           <dd>
          <label>상세주소<span class="forg"> *</span></label>
            <ul>
              <li>
                <input
                    ref="addrDtlRef"
                    class="k-textbox w100"
                    placeholder="상세주소"
                    v-model.trim="locaInfo.locaAddrDtl"
                />
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label >재고 출고지 설정<span class="forg"> *</span></label>
            <ul class="w80">
              <li class="out_setting">
                <common-radio-group
                    ref="activeYnRef"
                    :class="'w100'"
                    :data-items="$store.state.codeRequestStore.search.combobox.inoutDsgnGbn.data.filter(item=>item.customLabel = item.cdNm+'('+item.cdDesc1+')' )"
                    :data-value-field="'cdId'"
                    :data-text-field="'customLabel'"
                    :default-value="constant.inoutDsgnGbn.fix"
                    v-model.trim="locaInfo.inoutDsgnGbn"
                ></common-radio-group>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>사용여부<span class="forg"> *</span></label>
            <ul>
              <li>
                <common-radio-group
                    ref="activeYnRef"
                    :data-items='[{label: "활성화", value: "Y",},{label: "비활성화", value: "N",}]'
                    v-model.trim="locaInfo.activeYn"
                ></common-radio-group>
              </li>
            </ul>
          </dd>
          <dd><label>비고</label>
            <ul>
              <textarea
                  ref="contentsRef"
                  v-model.trim="locaInfo.contents"
              ></textarea>
            </ul>
          </dd>
        </li>
      </ul>
    </div>

    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>관리자정보
          <button
              class="mid_btn orange"
              @click="$refs.userSearchWindowRef.kendoWidget().center().open()">관리자검색</button>
        </h2>
        <ul class="filter_list">
          <li>
            <dd><label>성명</label>
              <ul><li>{{locaInfo.mngUnqUserNm}}</li></ul>
            </dd>
            <dd><label>ID</label>
              <ul><li>{{locaInfo.mngUserId}}</li></ul>
            </dd>
          </li>
          <li>
            <dd><label>휴대전화번호</label>
              <ul><li>{{locaInfo.spTelNo}}</li></ul>
            </dd>
            <dd><label>메일주소</label>
              <ul><li>{{locaInfo.email}}</li></ul>
            </dd>
          </li>
        </ul>
      </div>
    </div>

    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>프린터 IP</h2>
        <ul class="filter_list">
          <li>
            <dd><label>IP</label>
              <ul><li>
                <kendo-maskedtextbox
                    class="w20 ml5"
                    v-model.trim="locaInfo.printerIp1"
                    mask="000"
                ></kendo-maskedtextbox>
                <kendo-maskedtextbox
                    class="w20 ml5"
                    v-model.trim="locaInfo.printerIp2"
                    mask="000"
                ></kendo-maskedtextbox>
                <kendo-maskedtextbox
                    class="w20 ml5"
                    v-model.trim="locaInfo.printerIp3"
                    mask="000"
                ></kendo-maskedtextbox>
                <kendo-maskedtextbox
                    class="w20 ml5"
                    v-model.trim="locaInfo.printerIp4"
                    mask="000"
                ></kendo-maskedtextbox>
              </li></ul>
            </dd>
            <dd><label>포트</label>
              <ul><li>
                <kendo-maskedtextbox
                    class="w20 ml5"
                    v-model.trim="locaInfo.printerPort"
                    mask="0000"
                ></kendo-maskedtextbox>
              </li></ul>
            </dd>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="!isNew" class="contents_wrap">
      <div class="small_title_wrap"><h2>섹터정보</h2></div>
      <kendo-grid
          class="mt20"
          style="cursor: pointer"
          :data-source="sectListInfo"
          :navigatable="false"
          :sortable-mode="'multiple'"
          :reorderable="true"
          :column-menu="false"
          :resizable="true"
          :selectable="'row'"
          :columns="gridColumns"
          :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }">
      </kendo-grid>

    </div>

    <!--- 버튼영역 --->
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button class="large_btn"
                  @click="$router.go(-1)">취소</button>
        </li>
        <li class="w30">
          <button class="large_btn orange"
                  @click="onClickLocationInfoSave">저장</button>
        </li>
      </ul>
    </div>

    <kendo-window
        ref="userSearchWindowRef"
        :title="'관리자검색'"
        :modal="true"
        :width="'700px'"
        style="display:none; min-height: 500px;"
        @onPushUser="updateMngUser"
        @open="e=>{this.isWindowOpen = true}"
        @close="e=>{this.isWindowOpen = false}"
    >
      <component :is="isWindowOpen ? 'SearchUser' : null"></component>
    </kendo-window>

    <!-- 주소검색 window -->
    <daum-postcode-window ref="postcodeWindowRef"
                          @select-addr="data=>{
                            locaInfo.locaZipNo = data.zipCode
                            locaInfo.locaAddr = data.addr
                          }"
    ></daum-postcode-window>
  </div>
</template>

<script>

import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import SearchUser from '@/components/SearchUser'
import Constant from '@/common/constant'

export default {
  name      : 'LocationManageSave',
  components: {
    SearchUser,
    'DaumPostcodeWindow': () => import('/src/components/DaumPostcodeWindow.vue'),
  },
  computed : {
    isDisabledCheck : function (){
      const vm = this
      if(vm.locaInfo.locaNm === vm.bfLocaNm){
        return true
      }
      if(vm.isNew){
        return !vm.locaInfo.locaNm;
      }else {
        return vm.locaInfo.locaNm === vm.initLocaNm;
      }
    }
  },
  created () {
    if (!!this.$route.params.cmpyCd && !!this.$route.params.locaCd) {
      this.isNew = false
      this.getLocationManageDetail()
    }
  },
  mounted   : function () {
  },
  methods   : {
    initAllData : function (){
      const vm = this
      vm.locaInfo = {
        cmpyCd: vm.locaInfo.cmpyCd,
        locaZipNo : '',
        locaAddr : '',
        locaAddrDtl : ''
      }
      vm.isPassLocaNm = false
      vm.isCheckLocaNm = false
      vm.bfLocaNm = ''
    },
    updateMngUser : function (newMngUser){
      const vm = this
      let mngUser = newMngUser[0]
      vm.locaInfo.email = mngUser.email
      vm.locaInfo.mngCmpyCd = mngUser.cmpyCd
      vm.locaInfo.mngUnqUserId = mngUser.unqUserId
      vm.locaInfo.mngUserId = mngUser.userId
      vm.locaInfo.mngUnqUserNm = mngUser.userNm
      vm.locaInfo.spTelNo = mngUser.spTelNo
    },
    getLocationManageDetail : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/loca/${vm.$route.params.cmpyCd}/${vm.$route.params.locaCd}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.locaInfo = response.data.resultData
              let printIpSplit = vm.locaInfo.printerIp?.split('.') ?? []
              vm.locaInfo.printerIp1 = printIpSplit[0] ?? ''
              vm.locaInfo.printerIp2 = printIpSplit[1] ?? ''
              vm.locaInfo.printerIp3 = printIpSplit[2] ?? ''
              vm.locaInfo.printerIp4 = printIpSplit[3] ?? ''
              vm.sectListInfo = EtnersCommonUtil.setRowNumber(response.data.resultData.locaWhSectResponseList)
              vm.isPassLocaNm = false
              vm.isCheckLocaNm = true
              vm.bfLocaNm = response.data.resultData.locaNm
              vm.initLocaNm = response.data.resultData.locaNm
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    onClickLocationInfoSave () {
      const vm = this
      if (vm.validator()) {
        kendo.confirm('저장 하시겠습니까?').done(
          function () {
            vm.locationManageSave()
          })
      }
    },
    onClickCheckLocaNm () {
      const vm = this
      vm.checkLocaNm()
    },
    checkLocaNm : function (){
      const vm = this
      let param = {
        locaNm : vm.locaInfo.locaNm,
        cmpyCd : vm.locaInfo.cmpyCd,
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/loca/check`, param)
      .then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          kendo.alert(response.data.resultStatus.messageText)
          return false
        }

        let isPass = response.data.resultData
        if(isPass){
          kendo.alert('등록가능한 로케이션명입니다.')
          vm.isPassLocaNm = isPass
          vm.isCheckLocaNm = isPass
          vm.bfLocaNm = vm.locaInfo.locaNm
        }else{
          kendo.alert('중복된 로케이션명입니다.')
          vm.isPassLocaNm = !isPass
          vm.isCheckLocaNm = !isPass
        }
      })
    },
    locationManageSave() {
      const vm = this

      vm.locaInfo.printerIp = `${vm.locaInfo.printerIp1}.${vm.locaInfo.printerIp2}.${vm.locaInfo.printerIp3}.${vm.locaInfo.printerIp4}`
      if(vm.isNew){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/manage/loca`, vm.locaInfo)
        .then(response => {

          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          vm.$router.push({
            name  : 'LocationManageDetail',
            params: {
              cmpyCd : response.data.resultData.cmpyCd,
              locaCd : response.data.resultData.locaCd
            }
          })
        })
      }else{
        ApiUtil.put(`${ApiConfig.efsDomain}/efs/manage/loca/${vm.$route.params.cmpyCd}/${vm.$route.params.locaCd}`, vm.locaInfo)
        .then(response => {

          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          vm.$router.push({
            name  : 'LocationManageDetail',
            params: {
              cmpyCd : vm.$route.params.cmpyCd,
              locaCd : vm.$route.params.locaCd
            }
          })
        })
      }
    },
    validator(){
      const vm = this
      if(!vm.isCheckLocaNm && !(vm.locaInfo.locaNm === vm.initLocaNm || vm.locaInfo.locaNm === vm.bfLocaNm)){
        kendo.alert("로케이션명 중복체크를 진행해주세요.").bind('close', function() {
          vm.$refs.locaNmRef.focus()
        })
        return
      }
      if(!vm.locaInfo.locaNm){
        kendo.alert("로케이션명을 입력해주세요.").bind('close', function() {
          vm.$refs.locaNmRef.focus()
        })
        return
      }
      if(!vm.locaInfo.locaAddr){
        kendo.alert("주소를 입력해주세요.").bind('close', function() {
          vm.$refs.postcodeWindowRef.$refs.daumPostCodeRef.kendoWidget().center().open()
        })
        return
      }
      if(!vm.locaInfo.locaAddrDtl){
        kendo.alert("상세주소를 입력해주세요.").bind('close', function() {
          vm.$refs.addrDtlRef.focus()
        })
        return
      }
      if(!vm.locaInfo.activeYn){
        kendo.alert("활성화여부를 선택해주세요.").bind('close', function() {
          vm.$refs.activeYnRef.widget().focus()
        })
        return
      }
      if(!vm.locaInfo.mngUnqUserId){
        kendo.alert("관리자를 지정해주세요.").bind('close', function() {
          vm.$refs.userSearchWindowRef.kendoWidget().center().open()
        })
        return
      }

      return true
    },
  },
  data      : function () {
    const vm = this
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    return {
      Constant,
      constant : {
        inoutDsgnGbn : {
          fix: '01',
          select : '02'
        }
      },
      isWindowOpen : false,
      isNew : true,
      isCheckLocaNm : false,
      isPassLocaNm : false,
      rowItem : {
        data : {
          rowNumber : '',
        },
        index : '',
      },
      ApiConfig : ApiConfig,
      locaInfo  : {
        zipNo : '',
        locaAddr : '',
        locaAddrDtl : '',
      },
      bfLocaNm : null,
      initLocaNm : null,
      sectListInfo : [],
      gridColumns : [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'whNm',
          title: '구역명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'sectCd',
          title: '섹터코드',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'sectNm',
          title: '섹터명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'sectLocation',
          title: '위치',
          width: '20%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'goodsExistYn',
          title: '상품입고여부',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'area',
          title: '면적(평)',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
      ],
    }
  },
}
</script>

<style scoped>
</style>
